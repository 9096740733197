<template>
    <div>
        <v-card id="details" :loading="loading">
            <v-card-title>
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3 v-if="reserve.status != 'onHold'">Reserve</h3>
                <h3 v-else>Edit Reserve</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="mt-n5">
                <v-form v-model="valid" :disabled="!able">
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-text-field
                                hide-details
                                v-model="reserve.orderNumber"
                                label="Order Number"
                                prepend-icon="mdi-barcode"
                                :rules="
                                    reserve.status == 'approved'
                                        ? [rules.required]
                                        : []
                                "
                                :disabled="reserve.status == 'rejected'"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="reserve.status"
                                :items="status"
                                item-text="text"
                                item-value="value"
                                hide-details
                                label="Select Status"
                                prepend-icon="mdi-list-status"
                                :rules="[rules.required]"
                                required
                                :disabled="!approveStock"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-text-field
                                v-model="reserve.quantity"
                                hide-details
                                type="number"
                                label="Quantity"
                                prepend-icon="mdi-database"
                                :rules="[rules.required, numberNotValid]"
                                required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-textarea
                                v-model="reserve.notes"
                                rows="2"
                                hide-details
                                label="Notes"
                                prepend-icon="mdi-text"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-if="able"
                    color="primary"
                    class="mr-2 mb-3"
                    rounded
                    :disabled="!valid"
                    @click="edit"
                    :loading="loading"
                >
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import _ from 'lodash'

export default {
    name: 'ReserveForm',
    props: {
        reserve: {
            type: Object,
            default: () => {},
        },
        originalReserve: {
            type: Object,
            default: () => {},
        },
        approveStock: {
            type: String,
            default: () => '',
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        able: true,
        status: [
            { text: 'On Hold', value: 'onHold' },
            { text: 'Approved', value: 'approved' },
            { text: 'Rejected', value: 'rejected' },
        ],
        rules: {
            required: v => !!v || 'value is required',
        },
    }),
    computed: {
        reserveDiff: function() {
            return this.objDiff(this.originalReserve, this.reserve)
        },
    },
    mounted() {
        this.able = this.reserve.status == 'onHold'
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        numberNotValid(value) {
            if (parseFloat(value) > this.originalReserve.quantity) {
                return 'The value is not valid'
            } else if (parseFloat(value) <= 0) {
                return 'The value must be positive'
            } else {
                return true
            }
        },
        objDiff: function(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0

                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        async edit() {
            try {
                this.loading = true
                let paramsToUpdate = {
                    code: this.reserveDiff.code || this.reserve.code,
                    orderNumber: this.reserveDiff.orderNumber,
                    refOrder: `${this.reserve.item.order}`,
                    storage: this.reserve.item.storage,
                    projectRef: this.reserve.item.project_name,
                    ...this.reserveDiff,
                }
                if (this.reserveDiff.status == 'approved') {
                    paramsToUpdate.quantity =
                        this.reserveDiff.quantity || this.reserve.quantity
                }
                const reserve = await API.updateReserve(
                    this.reserve.id,
                    this.reserve.projectId,
                    paramsToUpdate
                )
                this.$emit('edit', reserve)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style></style>
